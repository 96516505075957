import React, { useState, useEffect } from 'react';
import axios from 'axios';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const DeleteSliderLine = () => {
  const [cards, setCards] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState('');
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    axios.get(`${baseURL}/api/cardlineproducts`)
      .then(response => setCards(response.data))
      .catch(error => console.error('Error fetching cardline products:', error));
  }, []);

  const handleDelete = () => {
    if (!selectedCardId) {
      showNotification('Будь ласка, виберіть картку для видалення.', 'error');
      return;
    }

    axios.delete(`${baseURL}/api/cardlineproducts/${selectedCardId}`)
      .then(() => {
        showNotification('Картка успішно видалена!', 'success');
        setCards(cards.filter(card => card._id !== selectedCardId));
        setSelectedCardId('');
      })
      .catch(error => {
        console.error('Error deleting card:', error);
        showNotification('Помилка при видаленні картки.', 'error');
      });
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  return (
    <div className='delete_card-line'>
      <div className="inner_delete-line">
        {notification && (
          <div className={`notification ${notification.type}`}>
            {notification.message}
          </div>
        )}
        <div className='card_line-select'>
          <p className='block_title'>Оберіть картку для видалення</p>
          <select
            id="card-select"
            value={selectedCardId}
            onChange={(e) => setSelectedCardId(e.target.value)}
          >
            <option value="">Оберіть картку</option>
            {cards.map(card => (
              <option key={card._id} value={card._id}>
                {card.title}
              </option>
            ))}
          </select>
        </div>
        <button className='card_line-btn' onClick={handleDelete}>Видалити картку</button>
      </div>
    </div>
  );
};

export default DeleteSliderLine;