import React, { useState, useEffect } from 'react';
import axios from 'axios';
import iconSlider from '../../../icon/other-icon/other-icon';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const EditSliderLine = () => {
  const [cards, setCards] = useState([]);
  const [selectedCardId, setSelectedCardId] = useState('');
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [imageDetails, setImageDetails] = useState('');
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.get(`${baseURL}/api/cardlineproducts`)
      .then(response => setCards(response.data))
      .catch(error => console.error('Error fetching cardline products:', error));
  }, []);

  useEffect(() => {
    if (selectedCardId) {
      const card = cards.find(c => c._id === selectedCardId);
      if (card) {
        setTitle(card.title);
        setImage(null);
        setImageDetails(card.image ? card.image.split('/').pop() : '');
      }
    }
  }, [selectedCardId, cards]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageDetails(`${file.name} (${file.type})`);
    }
  };

  const handleImageUpload = async () => {
    if (!image) return '';

    const formData = new FormData();
    formData.append('file', image);
    formData.append('upload_preset', 'ml_default');

    try {
      const res = await axios.post(
        'https://api.cloudinary.com/v1_1/dca5poo9m/image/upload',
        formData
      );
      return res.data.secure_url;
    } catch (error) {
      console.error('Image upload error:', error);
      return '';
    }
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const imageUrl = image ? await handleImageUpload() : undefined;

      const updateData = {
        title,
        ...(imageUrl && { image: imageUrl }),
      };

      await axios.patch(`${baseURL}/api/cardlineproducts/${selectedCardId}`, updateData);
      showNotification('Картка успішно відредагована!', 'success');
      setTitle('');
      setImage(null);
      setImageDetails('');
      setSelectedCardId('');
    } catch (error) {
      console.error('Error updating card:', error);
      showNotification('Помилка при редагуванні картки.', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='edit__line'>
      <div className="inner_edit-line">
        {notification && (
          <div className={`notification ${notification.type}`}>
            {notification.message}
          </div>
        )}
        <form className='line_form' onSubmit={handleSubmit}>
          <div className='card_line-select'>
            <p className='block_title'>Оберіть підкатегорію</p>
            <select
              id="card-select"
              value={selectedCardId}
              onChange={(e) => setSelectedCardId(e.target.value)}
            >
              <option value="">Виберіть картку</option>
              {cards.map(card => (
                <option key={card._id} value={card._id}>
                  {card.title}
                </option>
              ))}
            </select>
          </div>
          {selectedCardId && (
            <>
              <div className='card_line-select'>
                <p className='block_title'>Нова назва картки</p>
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <p className='block_title'>Завантажити нову картинку:</p>
              <div className='upload-item'>
                <input
                  type="file"
                  id="file-input"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                />
                <label htmlFor="file-input" className="custom-upload">
                  <img src={iconSlider.DwnIcon} alt="Завантажити" />
                  <span>{imageDetails || 'Завантажити нове зображення'}</span>
                </label>
              </div>
              <button className='card_line-btn-edit' type="submit" disabled={loading}>
                {loading ? 'Завантаження...' : 'Зберегти зміни'}
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default EditSliderLine;