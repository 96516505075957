import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import './slider.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const IntSlider = () => {
  const [slides, setSlides] = useState([]);
  const sliderRef = useRef(null);

  useEffect(() => {
    if (!baseURL) {
      console.error('REACT_APP_SERVER_BASE_URL не визначено в .env файлі');
      return;
    }
    axios.get(`${baseURL}/api/int-slider-cards`)
      .then(response => setSlides(response.data))
      .catch(error => console.error('Помилка при завантаженні карток слайдера', error));
  }, []);

  const formatPrice = (price) => {
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      axios.get(`${baseURL}/api/slider-cards`)
        .then(response => setSlides(response.data))
        .catch(error => console.error('Помилка при завантаженні карток слайдера', error));
    }, 10000);
  
    return () => clearInterval(interval);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      { breakpoint: 1400, settings: { slidesToShow: 7, slidesToScroll: 1 } },
      { breakpoint: 1200, settings: { slidesToShow: 6, slidesToScroll: 1 } },
      { breakpoint: 1024, settings: { slidesToShow: 5, slidesToScroll: 1 } },
      { breakpoint: 955, settings: { slidesToShow: 5, slidesToScroll: 1 } },
      { breakpoint: 820, settings: { slidesToShow: 4, slidesToScroll: 1 } },
      { breakpoint: 780, settings: { slidesToShow: 4, slidesToScroll: 1 } },
      { breakpoint: 680, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 580, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 490, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 450, settings: { slidesToShow: 2, slidesToScroll: 1 } },
    ],
  };

  return (
    <div className="slider__container">
      <div className="slider_header">
        <h2>Може зацікавити</h2>
        <div className="arrows_slide">
          <div
            className="arrows prev_arrow"
            onClick={() => sliderRef.current.slickPrev()}
          >
            &#10094;
          </div>
          <div
            className="arrows next_arrow"
            onClick={() => sliderRef.current.slickNext()}
          >
            &#10095;
          </div>
        </div>
      </div>
      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide) => (
          <div key={slide._id} className="slider-card">
            <a href={`/prodykt/${slide.product._id}`}>
              <img src={slide.image} alt={slide.productName} />
              <p className="card_slider-title">{slide.productName}</p>
              
              {slide.promo && slide.promo !== 'Без промо' && (
                <p
                  className="card_slider-promo"
                  style={{
                    backgroundColor: slide.promo === 'Хіт продажу' || slide.promo === 'Топ продажу'
                      ? 'orange'
                      : slide.promo === 'Новинка'
                      ? 'green'
                      : 'red',
                  }}
                >
                  {slide.promo}
                </p>
              )}

              <div 
                className="card_slider-price"
                style={{
                  color: slide.promo === 'Акція' ? 'red' : 'inherit',
                }}
              >
                {slide.oldPrice && (
                  <p className="card_slider-old-price" style={{color: '#121113',}}>
                    {formatPrice(slide.oldPrice)} грн
                  </p>
                )}
                {formatPrice(slide.price)} грн
              </div>

              <div className="color_item-card">
                {slide.color && (
                  <div className="card_slider-color" style={{ backgroundColor: slide.color }}></div>
                )}
              </div>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default IntSlider;