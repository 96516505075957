import React, { createContext, useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const socketRef = useRef(null);
    const [userCount, setUserCount] = useState(0);
    const [onlineUserCount, setOnlineUserCount] = useState(0);

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = io('https://store-tone.onrender.com');
        }
        const socket = socketRef.current;

        socket.on('userCount', (count) => {
            setUserCount(count);
        });

        socket.on('onlineRegisteredUsersCount', (count) => {
            setOnlineUserCount(count);
        });

        socket.on('connect_error', (err) => {
            console.error('Socket connection error:', err);
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <SocketContext.Provider value={{ socket: socketRef.current, userCount, onlineUserCount }}>
            {children}
        </SocketContext.Provider>
    );
};
