import React, { useState, useEffect } from 'react';
import TabPanel from './TabPanel';
import './panel.css';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function PanelPage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseURL}/api/admin/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('adminToken', data.token);
        setIsLoggedIn(true);
      } else {
        alert('Невірний логін або пароль');
      }
    } catch (error) {
      console.error('Помилка авторизації:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    setIsLoggedIn(false);
  };

  if (!isLoggedIn) {
    return (
      <div className="login-container_panel">
        <div className="login-panel">
          <div className="inner_login-panel">
            <form onSubmit={handleLogin}>
              <input
                type="text"
                placeholder="Логін"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button type="submit">Увійти</button>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="main-container">
      <div className="container">
        <div className="panel_container">
          <div className="inner_container">
            <div className="panel">
              <div className="item">
                <TabPanel onLogout={handleLogout} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PanelPage;