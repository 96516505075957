import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import icon from '../../icon/icon';

function SearchPanel() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleSearch = () => {
    if (searchQuery.trim()) {
      navigate('/search-results', { state: { query: searchQuery.trim() } });
    }
    setIsSearchOpen(false);
  };

  const handleKeyDown = (e) => {
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
    if ((!isMobileDevice && e.key === 'Enter') || (isMobileDevice && e.key === 'Search')) {
      handleSearch();
    }
  };

  return (
    <div>
      <button className="search_btn" onClick={toggleSearch}>
        <img src={icon.SearchIcon} alt="Search" />
      </button>

      {isSearchOpen && (
        <div className="search_overlay">
          <div className="serch_input-btn">
            <input
              type="text"
              className="search-input"
              placeholder="Введіть назву продукту..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <button className="search_submit" onClick={handleSearch}>
              Знайти
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchPanel;