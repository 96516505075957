import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Routes, Route } from 'react-router-dom';

import HomePage from './page/home/HomePage';
import CatalogPage from './page/catalog/CatalogPage';
import CategoriesPage from './page/category/CategoriesPage';
import PodCategoriesPage from './page/category/PodCategoriesPage';
import ProductPage from './page/product/ProductPage';
import CheckoutPage from './page/order/CheckoutPage';
import OrderConfirmation from './page/order/OrderConfirmation';

import Login from './page/cabinet/Login';
import Cabinet from './page/cabinet/Cabinet';
import ProtectedRoute from './ProtectedRoute';

import UserAgreement from './page/UserAgreement';
import DeliveryReturn from './page/DeliveryReturn';
import PrivacyPolicy from './page/PrivacyPolicy';

import SearchResultsPage from './components/search/SearchResultsPage';

import Panel from './page/panel/Panel';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

function App() {
  const [cityVisits, setCityVisits] = useState([]);

  useEffect(() => {
    axios.post(`${baseURL}/api/visits`)
      .then(response => {
      })
      .catch(error => {
        console.error('Error posting visit:', error.response?.data || error.message);
      });
  }, []);

  useEffect(() => {
    const handleUserVisit = async () => {
      try {
        const locationResponse = await axios.get('http://ip-api.com/json');
        const { city, lat, lon } = locationResponse.data;
        
        if (!city || !lat || !lon) {
          console.error('Incomplete location data:', locationResponse.data);
          return;
        }
  
        await axios.post(`${baseURL}/api/user-city`, {
          city,
          latitude: lat,
          longitude: lon,
        });

        const response = await axios.get(`${baseURL}/api/city-visits`);
        setCityVisits(response.data);
      } catch (error) {
        console.error('Error fetching or posting user city visit:', error);
      }
    };
  
    handleUserVisit();
  }, []);

  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/catalog-tovariv' element={<CatalogPage />} />
        <Route path='/katehoriya-tovaru/:id' element={<CategoriesPage />} />
        <Route path='/katehoriya/:id' element={<PodCategoriesPage />} />
        <Route path='/prodykt/:id' element={<ProductPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/order-confirmation" element={<OrderConfirmation />} />
        <Route path='/panel' element={<Panel />} />
        <Route path='/cabinet' element={<ProtectedRoute element={Cabinet} />} />
        <Route path='/login' element={<Login />} />
        <Route path='/user-agreement' element={<UserAgreement />} />
        <Route path='/povernennya-tovariv' element={<DeliveryReturn />} />
        <Route path='/polityka-konfidentsiynosti' element={<PrivacyPolicy />} />
        <Route path="/search-results" element={<SearchResultsPage />} />
      </Routes>
    </>
  );
}

export default App;