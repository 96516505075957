import React, { useState, useEffect } from 'react';
import axios from 'axios';
import iconSlider from '../../../icon/other-icon/other-icon';

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;

const CreateSliderLine = () => {
  const [image, setImage] = useState(null);
  const [imageDetails, setImageDetails] = useState('');
  const [title, setTitle] = useState('');
  const [selectedPage, setSelectedPage] = useState('');
  const [podCategories, setPodCategories] = useState([]);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios.get(`${baseURL}/api/catalog-cards`)
      .then(response => {
        setPodCategories(response.data);
      })
      .catch(error => console.error('Error fetching podcategories:', error));
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImageDetails(`${file.name} (${file.type})`);
    }
  };

  const handleImageUpload = async () => {
    if (!image) return '';

    const formData = new FormData();
    formData.append('file', image);
    formData.append('upload_preset', 'ml_default');

    try {
      const res = await axios.post(
        'https://api.cloudinary.com/v1_1/dca5poo9m/image/upload',
        formData
      );
      return res.data.secure_url;
    } catch (error) {
      console.error('Помилка завантаження зображення:', error);
      return '';
    }
  };

  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => setNotification(null), 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!image || !title || !selectedPage) {
      showNotification('Будь ласка, заповніть всі поля.', 'error');
      return;
    }

    setLoading(true);

    try {
      const imageUrl = await handleImageUpload();
      if (!imageUrl) throw new Error('Image upload failed');

      const formData = {
        image: imageUrl,
        title,
        page: selectedPage,
      };

      await axios.post(`${baseURL}/api/cardlineproducts`, formData);
      showNotification('Картка успішно створена!', 'success');
      setImage(null);
      setImageDetails('');
      setTitle('');
      setSelectedPage('');
    } catch (error) {
      console.error('Error creating card:', error);
      showNotification('Помилка при створенні картки.', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='create_line'>
      <div className="inner_create-line">
        {notification && (
          <div className={`notification ${notification.type}`}>
            {notification.message}
          </div>
        )}
        <form className='line_form' onSubmit={handleSubmit}>
          <p className='create_title'>Слайдер рекомендованих категорій</p>
          <div className='upload-item'>
            <input
              type="file"
              id="file-input"
              onChange={handleImageChange}
              style={{ display: 'none' }}
              required
            />
            <label htmlFor="file-input" className="custom-upload">
              <img src={iconSlider.DwnIcon} alt="Завантажити" />
              <span>{imageDetails || 'Завантажити картинку'}</span>
            </label>
          </div>

          <div className='edit_card'>
            <p className='block_title'>Назва категорії</p>
            <input 
              type="text" 
              id="title" 
              value={title} 
              onChange={(e) => setTitle(e.target.value)} 
              required
              placeholder='Назва категорії'
            />
            <p className='block_title'>Сторінка підкатегорії:</p>
            <select 
              id="page" 
              value={selectedPage} 
              onChange={(e) => setSelectedPage(e.target.value)} 
              required
            >
              <option value="" disabled>Виберіть сторінку підкатегорії</option>
              {podCategories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name || category.title}
                </option>
              ))}
            </select>
          </div>
          <button className='card_line-btn' type="submit" disabled={loading}>
            {loading ? 'Завантаження...' : 'Створити'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateSliderLine;